<template>
  <div>
    <div
      style="height: 95vh !important"
      class="text-center d-flex flex-1 justify-content-center align-items-center"
      v-if="IsLoading"
    >
      <b-spinner variant="success" type="grow"></b-spinner>
    </div>
    <div class="auth-wrapper auth-v1" v-if="!IsLoading">
      <div class="card d-flex wrapper-div">
        <div class="d-flex p-2 w-100 container-div">
          <div class="d-xl-flex flex-column justify-content-around d-none">
            <div
              class="d-flex flex-column justify-content-center align-items-center"
            >
              <b-img
                :src="appLogoImage"
                alt="logo"
                style="width: 50px"
                class="img-fluid"
              />
              <h3 class="text-center">{{ appName }}</h3>
            </div>
            <div>
              <img
                src="@/assets/images/reg.png"
                style="width: 100%; max-width: 400px"
              />
              <!-- <h1 class="text-center" style="font-size: 35px; margin-top: 10px">
              Just Register To Join With Us
            </h1> -->
              <h3 class="text-center" style="margin-top: 20px; font-size: 13pt">
                Just Register To Join With Us
              </h3>
            </div>
          </div>

          <div
            class="d-flex flex-1 flex-column justify-content-between p-1 responsive-div"
          >
            <div class="d-flex justify-content-between">
              <h2 class="brand-text text-primary d-inline-block">Register</h2>
              <img
                :src="appLogoImage"
                class="d-flex d-xl-none"
                style="width: 50px"
              />
              <b-link
                variant="primary"
                to="/login"
                class="cursor-pointer login-link"
              >
                <feather-icon icon="ArrowRightIcon" />
                Alreadey Have Account ?
              </b-link>
            </div>
            <!--* form -->
            <validation-observer
              style="height: 100%"
              class="d-flex flex-1 flex-column"
              ref="RegisterFrom"
              #default="{ invalid }"
            >
              <b-form
                style="height: 70%"
                class="d-flex flex-1 flex-column justify-content-between"
              >
                <!-- ! First Name -->
                <b-form-group label-for="First Name" label="First Name">
                  <validation-provider
                    #default="{ errors }"
                    name="First Name"
                    rules="required"
                  >
                    <b-form-input
                      id="first_name"
                      v-model="first_name"
                      name="register-first_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="john"
                      autofocus
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- ! Last Name -->
                <b-form-group label-for="Last Name" label="Last Name">
                  <validation-provider
                    #default="{ errors }"
                    name="Last Name"
                    rules="required"
                  >
                    <b-form-input
                      id="last_name"
                      v-model="last_name"
                      name="login-last_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="doe"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!--*Mail-->
                <b-form-group label-for="email" label="Email">
                  <validation-provider
                    #default="{ errors }"
                    name="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      type="email"
                      v-model="email"
                      @click="errorsMail = null"
                      :state="errors.length > 0 || errorsMail ? false : null"
                      name="login-email"
                      placeholder="johndoe@example.com"
                    />
                    <small class="text-danger"
                      >{{ errors[0] }} {{ errorsMail }}</small
                    >
                  </validation-provider>
                </b-form-group>
                <!-- password -->
                <b-form-group label-for="password" label="Password">
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password"
                        v-model="password"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        @click="errorsPasswords = null"
                        :state="
                          errors.length > 0 || errorsPasswords ? false : null
                        "
                        name="login-password"
                        placeholder="Password"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }} </small>
                  </validation-provider>
                </b-form-group>
                <!-- Confirm password -->
                <b-form-group
                  label-for="confirmPassword"
                  label="Confirm Password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Confirm Password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="confirmPassword"
                        v-model="confirmPassword"
                        :type="passwordFieldType"
                        class="form-control-merge"
                        @click="errorsPasswords = null"
                        :state="
                          errors.length > 0 || errorsPasswords ? false : null
                        "
                        name="login-password"
                        placeholder="Password"
                      />

                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger"
                      >{{ errors[0] }} {{ errorsPasswords }}
                    </small>
                  </validation-provider>
                </b-form-group>
                <div
                  class="mb-1 w-100 d-flex align-items-center justify-content-between"
                >
                  <div>
                    <!-- <input type="checkbox" name="" id=""> Accepted -->
                  </div>
                  <!-- <b-link variant="primary" to="/invite-mail">
                    <feather-icon
                      class="cursor-pointer"
                      icon="ArrowRightIcon"
                    />
                    Invitation To Access ?
                  </b-link> -->
                </div>

                <!-- submit button -->
                <b-button
                  variant="primary"
                  type="button"
                  @click="register"
                  block
                  :disabled="invalid"
                >
                  Sign up
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BImg,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
} from "bootstrap-vue";
import { required, regex } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";

import { getAccessTokenFromCookie } from "@/auth/utils";
import router from "@/router";
export default {
  components: {
    // BSV
    ValidationProvider,
    ValidationObserver,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BImg,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    const { appLogoImage, appName } = $themeConfig.app;
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
      notCompatible: false,
      userToken: "",
      erroR: null,
      appLogoImage,
      appName,
      required,
      regex,
      IsLoading: false,
      errorsPasswords: null,
      errorsMail: null,
    };
  },
  mounted() {
    // this.CheckUser();
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    register() {
      this.notCompatible = false;

      if (this.password !== this.confirmPassword) {
        this.notCompatible = true;
        this.errorsPasswords = "Passwords must match.";
        // this.$refs.RegisterFrom.errors.confirmPassword.push(
        //   "Passwords must match."
        // );
        // this.$refs.RegisterFrom.errors.Password.push("");
      }
      if (this.notCompatible && this.password.length < 8) {
        this.errorsPasswords =
          "Passwords must match and contain atleast 8 characters.";
      }
      if (this.password.length < 8 && !this.notCompatible) {
        this.errorsPasswords = "Passwords must contain atleast 8 characters.";
      }

      if (!this.notCompatible && this.password.length >= 8) {
        this.IsLoading = true;
        axios
          .post("/register-user", {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email,
            password: this.password,
            confirm_password: this.confirmPassword,
          })
          .then(({ data }) => {
            if (data.status === "success") {
              router.push({ name: "message-welcome" });
            } else {
              if (data.status === "failed") {
                this.IsLoading = false;
                this.showToast(
                  "danger",
                  "AlertTriangleIcon",
                  "Error !",
                  data.errors.email
                    ? data.errors.email[0]
                    : data.errors.password
                    ? data.errors.password[0]
                    : "Something Wrong!"
                );
              }
              this.IsLoading = false;
            }
          })
          .catch((error) => {
            this.IsLoading = false;
            if (error.status === 422) {
              this.errorsMail = error.data.errors.email[0];
            }
          });
      }
    },
    CheckUser() {
      this.userToken = this.$route.query.token;
      axios
        .post("/check-user-exists", {
          user_token: this.userToken,
        })
        .then(({ data }) => {
          if (data.status === "success") {
            this.IsLoading = false;
            this.email = data.email;
          } else {
            this.email = "";
            router.push("/email-invite");
          }
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    const data = getAccessTokenFromCookie();
    if (data) {
      const role = data[2].role;
      if (role === "admin") {
        next("/admin-dash");
      } else {
        next("dashboard");
      }
    } else {
      next(true);
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@media (min-width: 1200px) {
  .responsive-div {
    width: 70%;
  }
}
@media (max-width: 768px) {
  .wrapper-div {
    width: 95%;
  }
  .responsive-div {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .container-div {
    min-width: 400px;
  }
}

@media (max-width: 1200px) {
  .login-link {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .responsive-div {
    width: 100%;
  }
}
</style>

