var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.IsLoading)?_c('div',{staticClass:"text-center d-flex flex-1 justify-content-center align-items-center",staticStyle:{"height":"95vh !important"}},[_c('b-spinner',{attrs:{"variant":"success","type":"grow"}})],1):_vm._e(),(!_vm.IsLoading)?_c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"card d-flex wrapper-div"},[_c('div',{staticClass:"d-flex p-2 w-100 container-div"},[_c('div',{staticClass:"d-xl-flex flex-column justify-content-around d-none"},[_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-center"},[_c('b-img',{staticClass:"img-fluid",staticStyle:{"width":"50px"},attrs:{"src":_vm.appLogoImage,"alt":"logo"}}),_c('h3',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.appName))])],1),_vm._m(0)]),_c('div',{staticClass:"d-flex flex-1 flex-column justify-content-between p-1 responsive-div"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('h2',{staticClass:"brand-text text-primary d-inline-block"},[_vm._v("Register")]),_c('img',{staticClass:"d-flex d-xl-none",staticStyle:{"width":"50px"},attrs:{"src":_vm.appLogoImage}}),_c('b-link',{staticClass:"cursor-pointer login-link",attrs:{"variant":"primary","to":"/login"}},[_c('feather-icon',{attrs:{"icon":"ArrowRightIcon"}}),_vm._v(" Alreadey Have Account ? ")],1)],1),_c('validation-observer',{ref:"RegisterFrom",staticClass:"d-flex flex-1 flex-column",staticStyle:{"height":"100%"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"d-flex flex-1 flex-column justify-content-between",staticStyle:{"height":"70%"}},[_c('b-form-group',{attrs:{"label-for":"First Name","label":"First Name"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"first_name","name":"register-first_name","state":errors.length > 0 ? false : null,"placeholder":"john","autofocus":""},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"Last Name","label":"Last Name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"last_name","name":"login-last_name","state":errors.length > 0 ? false : null,"placeholder":"doe"},model:{value:(_vm.last_name),callback:function ($$v) {_vm.last_name=$$v},expression:"last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"email","label":"Email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 || _vm.errorsMail ? false : null,"name":"login-email","placeholder":"johndoe@example.com"},on:{"click":function($event){_vm.errorsMail = null}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" "+_vm._s(_vm.errorsMail))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 || _vm.errorsPasswords ? false : null,"name":"login-password","placeholder":"Password"},on:{"click":function($event){_vm.errorsPasswords = null}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"confirmPassword","label":"Confirm Password"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirmPassword","type":_vm.passwordFieldType,"state":errors.length > 0 || _vm.errorsPasswords ? false : null,"name":"login-password","placeholder":"Password"},on:{"click":function($event){_vm.errorsPasswords = null}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])+" "+_vm._s(_vm.errorsPasswords)+" ")])]}}],null,true)})],1),_c('div',{staticClass:"mb-1 w-100 d-flex align-items-center justify-content-between"},[_c('div')]),_c('b-button',{attrs:{"variant":"primary","type":"button","block":"","disabled":invalid},on:{"click":_vm.register}},[_vm._v(" Sign up ")])],1)]}}],null,false,3571621348)})],1)])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('img',{staticStyle:{"width":"100%","max-width":"400px"},attrs:{"src":require("@/assets/images/reg.png")}}),_c('h3',{staticClass:"text-center",staticStyle:{"margin-top":"20px","font-size":"13pt"}},[_vm._v(" Just Register To Join With Us ")])])}]

export { render, staticRenderFns }